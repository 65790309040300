import React, { useEffect } from 'react'
import Typing from 'react-typing-animation'

import "../css/normalize.css"
import "../css/reset.css"
import "../css/custom.css"
import "../css/fonts.css"
import Application from "../components/Application"
import Header from "../components/header"
// import Navmenu from "../components/navmenu"
import Footer from "../components/footer"
import GallerySlide from "../components/gallerySlide"


export default function Product({ pageContext }) {



  let linkto;
  const textShowSpeed = 25;
  // const textDeleteSpeed = 1;
  // const textDelay = 250;
  // const textDelay2 = 100;
  // const textInitTime = 1500;
  // const textPageTime = 2000;

  //add order number
  // const ulgallerywrap = document.querySelectorAll("ul.gallerywrap");
  // for (let i = 0; i < ulgallerywrap.length; i++) {
  //   const lielements = ulgallerywrap[i].querySelectorAll("li");
  //   for (let j = 0; j < lielements.length; j++) {
  //     lielements[j].querySelector("div.clicknum").innerHTML = ((j < 10) ? "0" : "") + (j + 1);
  //   }
  // }


  useEffect(() => {

    const alink = document.querySelectorAll("a.home");


    for (let i = 0; i < alink.length; i++) {
      alink[i].addEventListener("click", function () { delayClick(alink[i].rel) });
    }

    return () => {
    };
  }, []);

  const delayClick = (msg) => {
    linkto = msg

    var line1 = document.getElementsByClassName("line1")[0];
    const galleryUL = document.querySelectorAll("ul.gallerywrap");
    for (let i = 0; i < galleryUL.length; i++) {
      galleryUL[i].classList.remove("galleryIn");
      galleryUL[i].classList.add("galleryOut");
    }

    line1.classList.add("hide");
    line1.classList.remove("show");

    setTimeout(GoLink, 1000);
  }
  function GoLink() {
    // window.location.href = linkto;
    window.location.reload();
  }

  return (

    <div>
      <Header href="../../projects" className="detail"></Header>
      <div id="detailpage" className="clearfix">

        <div id="main" role="main" className="col three-quarters">

          <div id="menugroup" className="in">
            <h3 className="line1 show">
              <Typing speed={textShowSpeed} className={"classname"} hideCursor={true}>
                {/* {pageContext.section} */}
                {"" + pageContext.title}
              </Typing>
            </h3>
          </div>
        </div>

        <div className="sectionwrap">
          <div>
            <GallerySlide typeRef={"detail"} pathRef={pageContext.section + "/" + pageContext.project} classRef="galleryIn"></GallerySlide>
          </div>
        </div>
      </div>
      <Footer pageNow={pageContext.title} ></Footer>

      <Application pageNow={" / " + pageContext.title}></Application>
    </div>
  )
}